/* hamburger */

.hamburger {
    padding: 0;
    display: inline-block;
    transform: scale(0.5);
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }
.hamburger:hover {
    opacity: 0.7; }
.hamburger.is-active:hover {
    opacity: 0.7; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #2e354c; }

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #354169;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
.hamburger-inner::before {
    top: -10px; }
.hamburger-inner::after {
    bottom: -10px; }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/* navigation */


.navigation {
    display: flex;
    flex-direction: column-reverse;
}

.navigation.active {
    bottom: 0;
}

.navigation-panel-top {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 70px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    z-index: 10;
}

.menu-logo,
.navigation-company, .navigation-logout {
    display: none;
}

/* menu */

.menu-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2e354c;
    padding: 115px 0 40px 0;
    transform: translateX(-100%);
    transition: all 0.3s;
    overflow-y: scroll;
    z-index: 9;
}

.menu-wrap.active {
    transform: translateX(0);
}

.menu {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.menu-select-title {
    display: block;
    color: #b8c8e7;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}

.menu-select {
    margin-bottom: 35px;
    padding-left: 20px;
}

.navigation-panel-top-company .active-team-toggle-selector {
    min-width: 205px;
}

.menu-select .active-team-toggle-selector {
    min-width: 280px;
}

.menu-link {
    display: flex;
    align-items: center;
    color: #b8c8e7;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    padding: 15px 5px 15px 20px;
    user-select: none;
    position: relative;
}

.menu-link:hover .icon-wrap .item-icon,
.menu-link:hover .menu-link-text {
    color: #fff;
}

.menu-link.active:hover .icon-wrap .item-icon,
.menu-link.active:hover .menu-link-text,
.menu-inner-link.active:hover {
    color: #b8c8e7;
}

.menu-link-red-dot {
    height: 9px;
    width: 9px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ae3636;
    position: absolute;
    left: 26px;
    top: 13px;
    z-index: 1;
}

.menu-logout {
    color: #18dcb6;
    font-size: 14px;
    font-weight: 400;
}

.menu-link .icon-wrap {
    width: 18px;
    margin-right: 25px;
}

.menu-link .icon-wrap .item-icon {
    color: #b8c8e7
}

.menu-logout-wrap .icon-wrap {
    width: 16px;
    margin-right: 20px;
}

.menu-logout-wrap .icon-wrap .item-icon {
    font-size: 14px;
    color: #18dcb6;
}

.menu-logout-wrap {
    display: flex;
    padding-left: 20px;
    margin-top: 35px;
}

.navigation-panel-top-moved {
    transition: margin-left 0.3s;
}

.navigation-panel-top-title-company,
.navigation-panel-top-company,
.navigation-panel-top-logout {
    display: none;
}

.navigation-panel-top-title-company {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
}

.menu-inner-link {
    display: block;
    color: #b8c8e7;
    padding: 15px 20px 15px 64px;
    font-size: 14px;
    letter-spacing: 0.35px;
}

.menu-inner-link:hover {
    color: #fff;
}

.menu-link .icon-dropdown {
    margin-left: 45px;
}

.menu-link:hover .icon-dropdown {
    color: #fff;
}

.icon-dropdown {
    transition: transform ease 0.3s;
}

.menu-link-is-open .icon-dropdown {
    transform: rotate(180deg);
}

.icon-dropdown-svg {
    font-size: 12px;
}

/* nav-person */

.navigation-panel-top-person {
    display: flex;
    align-items: center;
}

.navigation-panel-top-person-name {
    color: #354169;
    font-size: 16px;
    font-weight: 700;
    margin-right: 15px;
    cursor: pointer;
}

.navigation-panel-top-person-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    margin-right: 15px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
.navigation-panel-top-person-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .navigation {
        position: static;
        overflow: hidden;
        flex-direction: column;
    }
    .navigation.active {
        bottom: unset;
    }
    .navigation-panel-top-person {
        margin-right: 40px;
    }
    .navigation-panel-top-profile {
        display: flex;
        align-items: center;
    }
    .navigation-panel-top-logout {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .navigation-panel-top-logout-text {
        color: #b7beca;
        font-size: 12px;
        margin: 0 10px 0 0;
    }
    .navigation-panel-top-logout:hover .navigation-panel-top-logout-text,
    .navigation-panel-top-logout:hover .icon-wrap .item-icon {
        color: #2e354c;
    }
    .menu-logout-wrap,
    .menu-select {
        display: none;
    }
    .navigation-panel-top {
        min-height: 80px;
        padding-right: 80px;
    }
    .menu-wrap {
        position: fixed;
        top: 0;
        transform: translateX(0);
        padding-top: 0;
        max-width: 80px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.3s;
        z-index: 11;
    }
    .menu-overflow {
        width: 265px;
    }
    .menu-wrap.active {
        max-width: 265px;
    }
    .menu-logo-helper-hidden {
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
        height: 80px;
        background-color: #202535
    }
    .menu-logo {
        display: flex;
        width: 250px;
        height: 80px;
        margin-bottom: 25px;
        background: #202535 url('../../assets/img/logo_white.svg') no-repeat 20px 50%;
        background-size: 69%;
        cursor: pointer;
    }
    .menu-logo img {
        object-fit: contain;
        margin-left: 21px;
    }
    .menu-link {
        padding-left: 32px;
    }
    .menu-link .icon-wrap {
        margin-right: 32px;
    }
    .menu-link-red-dot {
        left: 38px;
    }
    .navigation-panel-top-company {
        display: block;
    }
    .navigation-panel-top-moved {
        margin-left: 85px;
        display: flex;
        align-items: center;
    }
    .navigation-panel-top-company {
        margin-left: 30px;
    }
    .navigation-panel-top-moved.active {
        margin-left: 265px;
    }
    .menu-link.active .icon-wrap {
        position: relative;
    }
    .menu-link.active .icon-wrap:before {
        position: absolute;
        display: block;
        content: '';
        top: -8px;
        left: -10px;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background-color: #000000;
        opacity: 0.1;
    }
    .menu-wrap.active .menu-link.active .icon-wrap:before {
        display: none;
    }
    .menu-wrap.active .menu-link.active,
    .menu-wrap.active .menu-inner-link.active {
        background-color: #202535;
    }
    .menu-item {
        position: relative;
    }
    .menu-inner-link-wrap {
        display: none;
    }
    .menu-inner-link {
        padding-left: 82px;
    }
    .menu-wrap.active .menu-inner-link-wrap {
        display: block;
    }
}

@media screen and (min-width: 992px) {
    .navigation-panel-top-title-company {
        display: block;
        margin-left: 35px;
    }
    .navigation-panel-top-company {
        margin-left: 20px;
    }
}

@media screen and (min-width: 1600px) {
    .menu-wrap {
        max-width: 265px;
    }
    .navigation-panel-top-moved {
        margin-left: 250px;
    }
    .menu-inner-link-wrap {
        display: block;
    }
    .menu-link.active .icon-wrap:before {
        display: none;
    }
    .menu-wrap .menu-link.active {
        background-color: #202535;
    }
    .hamburger {
        display: none;
    }
    .menu-inner-link.active {
        background-color: #202535;
    }
}
